function App() {
  return (
    <div className="h-screen flex flex-col justify-center items-center text-center gap-y-2 p-2 ">
      <h1 className="text-2xl font-bold">Hello! 👋 I'm Sundar Gurung</h1>
      <p className="text-lg ">I'm a passionate UI/UX designer.</p>
      <div className="flex gap-x-2 gap-y-2  justify-center flex-wrap">
        <span className="bg-cyan-600 text-white p-2 rounded">🇳🇵 Nepal</span>
        <span className="bg-cyan-600 text-white p-2 rounded">
          🎓 BSc Hons Computer System Engineering
        </span>
      </div>
      <p className="bg-red-500 p-3 rounded text-white mt-5">
        ⚠️This site is under construction⚠️
      </p>
    </div>
  );
}

export default App;
